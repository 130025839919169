.page {
    width: 100%;
    height: 100%;
    position: absolute;
}

@media screen and (min-width: 1024px) {
    .top-tags {
            bottom: auto;
            top: 35px;
        }
    
        .tags {
            color: #ffd700;
            opacity: 0.6;
            position: absolute;
            bottom: 0;
            left: 120px;
            font-size: 18px;
            font-family: 'La Belle Aurore'
        }
    
        .bottom-tag-html {
            margin-left: -20px;
        }
}

@media screen and (max-width: 1024px) {
    .top-tags,
    .tags,
    .bottom-tag-html {
        display: none;
    }
    
}

.noAnimated {
    display: none;
}

.container {
    width: 100%;
    will-change: contents;
    height: 90%;
    min-height: 566px;
    position: absolute;
    opacity: 0;
    top: 5%;
    margin: 0 auto;
    z-index: 1;
    transform-style: preserve-3d;
    animation: fadeIn 1s forwards;
    animation-delay: 1s;
}

.about-page,
.contact-page {
    .text-zone {
        position: absolute;
        left: 10%;
        top: 50%;
        transform: translateY(-50%);
        width: 35%;
        vertical-align: middle;
        display: table-cell;
        max-height: 90%;

        h1 {
            font-size: 53px;
            font-family: 'Coolvetica';
            color: #ffd700;
            font-weight: 400;
            margin-top: 0;
            position: relative;
            margin-bottom: 40px;
            left: 10px;
            
        }

        p {
            font-style: 13px;
            font-size: 15px;
            color: #fff;
            font-family: sans-serif;
            font-weight: 300;
            min-width: fit-content;
            animation: pulse 1s;

            &:nth-of-type(1) {
                animation-delay: 1.1s;
            }

            &:nth-of-type(2) {
                animation-delay: 1.2s;
            }

            &:nth-of-type(3) {
                animation-delay: 1.3s;
            }
        }

    }

    .text-animate-hover {
        &:hover {
            color: #fff;
        }
    }
}

@media screen and (max-width: 1024px){
    .page {
        width: 100%;
        height: 95vh;
        max-height: 95vh;
        position: absolute;
    }
    .container {
        height: 95vh;
        max-height: 95vh;
        top: 0;
        left: 0;
        transform: none;
        opacity: 1;
    } 
    .home-page {
        .text-zone {
            width: 90vw;
            margin-left: 5vw;
            margin-right: 5vw;
            margin-top: 20vh;
            .animated {
                display: none;
            }
            .noAnimated {
                animation: fadeIn 1s 1.8s backwards;
                display: block;
                font-size: 20px;
                text-align: center;
                &::before {
                    content: "";
                }
                &::after {
                    content: "";
                }
            }
        }
    }
        .about-page,
        .contact-page {
            .text-zone {
                width: 90vw;
                margin-left: 5vw;
                margin-right: 5vw;
                left: 0;
                animation: fadeIn 1s 1.8s backwards;
            }
        }
}