@media screen and (min-width: 1024px) {
.nav-bar {
    background: #181818;
    width: 60px;
    height: 100%;
    position: absolute;
    top: 0;
    z-index: 3;
    min-height: 500px;

    .logo {
        display: block;
        padding: 8px 0;
        
        img {
            display: block;
            margin: 8px auto;
            width: 100%;
            height: auto;

            &.sub-logo {
                width: 50px;
            }
        }
    }

    nav {
        display: block;
        text-align: center;
        position: absolute;
        height: 210px;
        top: 50%;
        margin-top: -120px;
        width: 100%;

        a {
            font-size: 22px;
            color: #4d4d4e;
            display: block;
            line-height: 51px;
            height: 51px;
            position: relative;
            text-decoration: none;
            font-family: 'Coolvetica';

            i {
                transition: all 0.3s ease-out;
            }
        
            &:hover {
                color: #ffd700;
        
                svg {
                    opacity: 0;
                }
        
                &:after {
                    opacity: 1;
                }
            }
        
            &:after {
                content: '';
                font-size: 9px;
                letter-spacing: 2px;
                position: absolute;
                bottom: 0;
                display: block;
                width: 100%;
                text-align: center;
                opacity: 0;
                transition: all 0.3s ease-out;
            }
        
            &:first-child {
                &:after {
                    content: 'Accueil';
                }
            }
        }
        
        a.about-link {
            &:after {
                content: 'A propos';
            }
        }

        a.project-link {
            &:after {
                content: 'Projets';
            }
        }

        a.contact-link {
            &:after {
                content: 'Contact';
            }
        }

        a.active {
            svg {
                color: #ffd700;
            }
        }
    }

    ul {
        position: absolute;
        bottom: 20px;
        width: 100%;
        display: block;
        padding: 0;
        list-style: none;
        text-align: center;

        li {
            a {
                padding: 7px 0;
                display: block;
                font-size: 15px;
                line-height: 16px;

                &:hover svg {
                    color: #ffd700
                }
            }
        }
    }
}
}

@media screen and (max-width: 1024px) {
    // Just put the three icons on the bottom of page
    .nav-bar {
        position: fixed;
        bottom: 0;
        top: auto;
        height: 5vh;
        max-height: 5vh;
        background: #181818;
        width: 100%;
        min-height: 0;
        padding: 0;
        z-index: 3;

        .logo {
            display: none;
        }

        nav {
            // The nav needs to be on the bottom of the page, and horizontally aligned
            display: flex;
            justify-content: space-around;
            align-items: center;
            width: 80vw;
            height: 100%;
            margin-right: 20vw;
            margin-left: 0;
            margin-top: 0;
            margin-bottom: 0;

            a {
                    font-size: 22px;
                    color: #4d4d4e;
                    display: block;
                    height: 3vh;
                    max-height: 3vh;
                    position: relative;
                    text-decoration: none;
                    font-family: 'Coolvetica';
            
                    i {
                        transition: all 0.3s ease-out;
                    }
            
                }
            
                a.active {
                    svg {
                        color: #ffd700;
                    }
                }
        }

        ul {
            width: 20vw;
            height: 100%;
            position: absolute;
            right: 0;
            top: 0;
            list-style: none;
            text-align: center;
            padding: 0;
            margin: 0;
            // Here we need to align the icons horizontally
            display: flex;
            justify-content: space-around;
            align-items: center;
            margin-right: 5vw;
            left: 75vw;

            li {
                a {
                    padding: 7px 0;
                    display: block;
                    font-size: 3vh;
                    line-height: 16px;
                    color: #4d4d4e;

                    &:visited {
                        color: #4d4d4e;
                    }
                }
            }
        }
    }
}