.liste-projets {
    margin-left: 60px; // Ajustez la marge en fonction de la largeur de votre navbar
    padding: 20px; // Ajoutez de la marge intérieure pour l'espace entre les projets
    display: flex;
    flex-wrap: wrap;
    margin-top: 35px; // Ajout de la marge supérieure de 35px
    justify-content: center;
    animation: fadeIn 1s 1.8s backwards;
    overflow-y: auto;
    max-height: 90vh;

    @media (max-width: 1024px) {
        margin-left: 0;
        padding: 0;
    }
}

.projet {
    width: 27vw;
    background-color: #13638F;
    border: 1px solid #FFD700;
    border-radius: 8px;
    margin: 10px;
    padding: 20px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    text-align: center;

    @media (max-width: 1024px) {
        width: 100%;
    }

    img {
        max-width: 100%;
        height: auto;
        margin-bottom: 10px;
    }

    h3 {
        font-size: 1.7em;
        margin-bottom: 10px;
        color: #FFD700;
    }

    p {
        font-size: 1.2em;
        color: #fff;
        animation: fadeIn 1s 2.5s backwards;
    }

    .fa-icon {
        margin-right: 5px;
    }
}