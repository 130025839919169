.contact-form {
    width: 100%;
    margin-top: 20px;

    ul {
        padding: 0;
        margin: 0;

        li {
            padding: 0;
            margin: 0;
            list-style: none;
            margin-bottom: 10px;
            opacity: 0;
            overflow: hidden;
            display: block;
            clear: both;
            position: relative;
            animation: fadeInUp 2s 2s;
            animation-fill-mode: forwards;
        }

        li.half {
            width: 49%;
            margin-left: 2%;
            float: left;
            clear: none;

            &:first-child {
                margin-left: 0;
            }
        }
    }

    input[type='text'],
    input[type='email'] {
        width: 100%;
        border: 0;
        background: #115173;
        height: 50px;
        font-size: 22px;
        color: #fff;
        padding: 0 20px;
        box-sizing: border-box;
    }

    textarea {
        width: 100%;
        border: 0;
        background: #115173;
        height: 50px;
        font-size: 22px;
        color: #fff;
        padding: 20px;
        box-sizing: border-box;
        min-height: 150px;
    }

    .captcha {
        width: 100%;
        height: 100%;
        
    }

    @media screen and (min-width: 1024px) {
        .flat-button {
                color: #ffd700;
                font-size: 11px;
                letter-spacing: 3px;
                text-decoration: none;
                padding: 8px 10px;
                border: 1px solid #ffd700;
                float: left;
                border-radius: 4px;
                background: 0 0;
                text-transform: uppercase;
                float: right;
                text-align: center;
                margin-right: 10px;
        
                &:hover {
                    background: #ffd700;
                    color: #115173;
                }
            }
    }
    @media screen and (max-width: 1024px) {
        .flat-button {
            top: 0;
            margin-top: 5vh;
            color: #ffd700;
            font-size: 11px;
            letter-spacing: 3px;
            text-decoration: none;
            padding: 8px 10px;
            border: 1px solid #ffd700;
            background: 0 0;
        }
    }
    
}