.home-page {

    @media screen and (min-width: 1024px) {
        .text-zone {
                position: absolute;
                left: 10%;
                top: 50%;
                transform: translateY(-50%);
                width: 40%;
                max-height: 90%;
            }
    }
    

    h1 {
        color: #fff;
        font-size: 53px;
        margin: 0;
        font-family: 'Coolvetica';
        font-weight: 400;

        img {
            width: 40px;
            margin-left: 20px;
            margin-bottom: -6px;
            opacity: 0;
            height: auto;
            animation: rotateIn 1s linear both;
            animation-delay: 1.4s;
        }
    }

    h2 {
        color: #8d8d8d;
        margin-top: 20px;
        font-weight: 400;
        font-size: 11px;
        font-family: sans-serif;
        letter-spacing: 3px;
        animation: fadeIn 1s 1.8s backwards;
    }

        @media screen and (min-width: 1024px) {
        .flat-button {
            color: #ffd700;
            font-size: 13px;
            font-weight: 400;
            letter-spacing: 4px;
            font-family: sans-serif;
            text-decoration: none;
            padding: 10px 18px;
            border: 1px solid #ffd700;
            margin-top: 25px;
            float: left;
            animation: fadeIn 1s 1.8s backwards;
            white-space: nowrap;

            &:hover {
                background-color: #ffd700;
                color: #333;
            }
        }
    }

}

@media screen and (max-width: 1024px) {
    h2 {
        font-size: 10px;
        text-align: center;
    }
 
    .flat-button {
        color: #ffd700;
        font-size: 13px;
        font-weight: 400;
        letter-spacing: 4px;
        font-family: sans-serif;
        text-decoration: none;
        padding: 10px 18px;
        border: 1px solid #ffd700;
        top: 15vh;
        position: relative;
        animation: fadeIn 1s 1.8s backwards;
        white-space: nowrap;
        // center button
        margin: 0 auto;
        display: block;
        text-align: center;
    
        &:hover {
            background-color: #ffd700;
            color: #333;
        }
    }
}